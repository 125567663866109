import React, { Fragment } from "react";
const Privacy = () => {
  return (
    <Fragment>
      <div className="space-top space-extra-bottom">
        <div className="container">
          <div className="col-12">
            <h4>Privacy Policy </h4>
            <p>
              Your privacy is important to us, and we are committed to
              protecting your personal information. This Privacy Policy outlines
              how we collect, use, disclose, and protect the information you
              provide when using our app.
            </p>

            <h5>1. Information we collect</h5>
            <br></br>
            <p>
              1.1. Personal Information: When you register an account, place
              orders, or communicate with us, we may collect personal
              information such as your name, email address, phone number,
              delivery address, and payment information. We do not save your
              card details.
            </p>
            <p>
              1.2. Usage Information: We collect information about how you
              interact with our app, including pages visited, actions taken, and
              device information such as IP address, device type, and operating
              system.
            </p>
            <h5>2. How We Use Your Information</h5>
            <p>
              2.1. Order Processing: We use your personal information to process
              orders, provide delivery services, and communicate order status.
            </p>

            <p>
              2.2. Customer Support: Your information helps us respond to your
              inquiries, resolve issues, and provide support.
            </p>

            <p>
              2.3. Improvement: We analyze usage data to improve our app's
              functionality, user experience, and offerings.
            </p>

            <p>
              2.4. Marketing: With your consent, we may send promotional offers,
              updates, and relevant information about our services.
            </p>

            <h5> 3. Information Sharing </h5>
            <p>
              3.1. Service Providers: We may share your information with
              third-party service providers such as delivery partners, payment
              processors, and IT service providers to fulfill orders and improve
              our services.
            </p>
            <p>
              3.2. Legal Compliance: We may disclose information when required
              by law, legal process, or government request.
            </p>

            <h5> Data Security </h5>

            <p>
              4.1. We implement security measures to protect your personal
              information from unauthorized access, alteration, disclosure, or
              destruction.
            </p>

            <p>
              4.2. However, no method of transmission over the internet or
              electronic storage is 100% secure. While we strive to use
              commercially acceptable means to protect your information, we
              cannot guarantee absolute security.
            </p>

            <h5>Data Retention</h5>

            <p>
              5.1. We retain your personal information for as long as necessary
              to fulfill the purposes outlined in this Privacy Policy, unless a
              longer retention period is required or permitted by law.
            </p>

            <h5>Your Choices</h5>

            <p>
              6.1. Account Information: You can review and update your account
              information through the app settings.
            </p>

            <p>
              6.2. Marketing Communications: You can opt-out of receiving
              marketing communications from us by following the instructions in
              the messages or contacting us directly.
            </p>

            <h5>Children's Privacy</h5>

            <p>
              7.1. Our services are not directed to individuals under the age of
              18. If we become aware that we have collected personal information
              from children without parental consent, we take steps to remove
              such information.
            </p>

            <h5>Changes to Privacy Policy</h5>

            <p>
              8.1. We reserve the right to update or modify this Privacy Policy
              at any time. Changes will be effective immediately upon posting on
              the app. Your continued use of the app after changes indicates
              acceptance of the updated Privacy Policy.
            </p>

            <h5>Contact Us</h5>

            <p>
              9.1. If you have any questions, concerns, or requests regarding
              this Privacy Policy or your personal information, please email us
              to creators@datumailabs.com or write feedback in the link below.
              <a href="https://www.datumailabs.com/contact">
                www.datumailabs.com
              </a>
            </p>

            <p>
              By using our Food Ordering App, you acknowledge that you have read
              and agree to the terms of this Privacy Policy.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Privacy;
