import vegetabledumbiryani from "../assets/menuimages/biriyani/vegetabledumbiriyani.jpg";
import chickenBriyani from "../assets/menuimages/biriyani/chickenBriyani.jpg";
import supremebonelesschickenbiryani from "../assets/menuimages/biriyani/supremebonelesschickenbiriyani.jpg";
import vegBriyani from "../assets/menuimages/biriyani/vegBriyani.jpg";
import gheeRice from "../assets/menuimages/biriyani/gheeRice.jpg";
import prawnBriyani from "../assets/menuimages/biriyani/prawnBriyani.jpg";
import mushroomBriyani from "../assets/menuimages/biriyani/mushroomBriyani.jpg";
import plainBriyani from "../assets/menuimages/biriyani/plainBriyani.jpg";


export const biryaniVeg = [
  {
    category: "Biryani Veg",
    foodName: "Veg Briyani",
    price: 6.25,
    description:
      "Aromatic and flavorful rice dish cooked with assorted vegetables, spices, and fragrant herbs, a vegetarian delight.",
    image: `url(${vegBriyani})`,
  },
  {
    category: "Biryani Veg",
    foodName: "Gee Rice",
    price: 5.65,
    description:
      " Fragrant and flavorful rice cooked with ghee (clarified butter), enhancing the taste and aroma of the dish.",
    image: `url(${gheeRice})`,
  },
  {
    category: "Biryani Veg",
    foodName: "Mushroom Briyani",
    price: 9.40,
    description:"Mushroom biryani is a fragrant and flavorful rice dish cooked with aromatic spices, tender mushrooms, and basmati rice, offering a delicious vegetarian twist on the traditional biryani.",
    image: `url(${mushroomBriyani})`,
  },
  {
    category: "Biryani Veg",
    foodName: "Plain Briyani",
    price: 6.90,
    description:"Plain biryani is a fragrant and aromatic rice dish cooked with basmati rice and a blend of whole spices, offering a flavorful and versatile base that can be paired with various proteins or enjoyed on its own.",
    image: `url(${plainBriyani})`,
  },
];

export const biryaniNonVeg = [
  {
    category: "Biryani Non-Veg",
    foodName: "Chicken Briyani",
    price: 6.25,
    description:
      "A classic Indian dish of fragrant basmati rice cooked with tender chicken pieces, aromatic spices, and herbs, a true culinary delight.",
    image: `url(${chickenBriyani})`,
  },
  {
    category: "Biryani Non-Veg",
    foodName: "Lamb Briyani",
    price: 8.15,
    description:
      "A rich and aromatic rice dish cooked with tender lamb pieces, fragrant spices, and herbs, a luxurious and indulgent meal option.",
    image: `url(${supremebonelesschickenbiryani})`,
  },
  {
    category: "Biryani Non-Veg",
    foodName: "Prawn Briyani",
    price: 8.75,
    description:
      "A rich and aromatic rice dish cooked with tender lamb pieces, fragrant spices, and herbs, a luxurious and indulgent meal option.",
    image: `url(${prawnBriyani})`,
  },
  
  {
    category: "Biryani Non-Veg",
    foodName: "Food Stn Spl Briyani",
    price: 9.40,
    description:
      "Chef's special biryani preparation featuring premium ingredients, aromatic spices, and exquisite flavors.",
    // image: `url(${eggbriyani})`,
  },
];
