import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import googleplaystore from "../assets/images/GooglePlayStore.png";
import appleplaystore from "../assets/images/ApplePlayStore.png";
import Faq from "./Faq";
import Feedback from "./Feedback";

const Footer = () => {
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const toggleFaq = () => {
    setIsFaqOpen(!isFaqOpen);
  };

  const toggleFeedback = () => {
    setIsFeedbackOpen(!isFeedbackOpen);
  };

  const popupStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const popupInnerStyle = {
    position: "relative",
    width: "95%",
    maxWidth: "600px",
    backgroundColor: "#fff",
    padding: "20px",
    boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
    borderRadius: "10px",
  };

  const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "1.5em",
    cursor: "pointer",
  };

  return (
    <footer className="site-footer" role="contentinfo">
      <Container fluid>
        <Row>
          <Col md={12} className="text-center">
            <div className="container" style={{ textAlign: "left" }}>
              <div className="row mb-5">
                <div className="col-md-4 mb-5">
                  <h3>About Us</h3>
                  <p className="mb-5">
                    "With our mouth-watering meals, super fast Restaurant and
                    takeaway service, we intend to create magic for all our
                    customers. We assure you that every single member of our
                    staff will give you the finest food experience which will
                    undoubtedly entice you to come back for more. We are
                    committed to providing you with the best culinary experience
                    and also keep maintaining the highest hygiene standards.
                    Great food and limited-time special offers are always
                    available at Food Station, Croydon. Feel free to browse
                    through our brand new website and place an online order."
                  </p>
                  <ul className="list-unstyled footer-link d-flex footer-social">
                    <li>
                      <span className="fa fa-facebook p-2"></span>
                    </li>
                    <li>
                      <span className="fa fa-instagram p-2"></span>
                    </li>
                  </ul>
                  <div>
                    <ul className="list-unstyled footer-link">
                      <li className="d-block">
                        <span className="d-block text-black">
                          <strong className="text-black">Address:</strong>
                        </span>
                        <span>77A Station Road, Croydon </span>
                        <br />
                        <span>London, CR0 2RD</span>
                      </li>
                      <li className="d-block">
                        <span className="d-block text-black">Phone:</span>
                        <span>020 8760 9999</span>
                      </li>
                      <li className="d-block">
                        <span className="d-block text-black">Email:</span>
                        <span> croydonfoodstation@gmail.com</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-5 mb-5">
                  <div className="mb-5">
                    <h3>Opening Hours</h3>
                    <table>
                      <tr>
                        <th>Day</th>
                        <th>Pickup</th>
                        <th>Delivery</th>
                      </tr>
                      <tr>
                        <td>Sunday</td>
                        <td>09:00AM-10:00PM</td>
                        <td>09:00AM-10:00PM</td>
                      </tr>
                      <tr>
                        <td>Monday</td>
                        <td>09:00AM-10:00PM</td>
                        <td>09:00AM-10:00PM</td>
                      </tr>
                      <tr>
                        <td>Tuesday</td>
                        <td>09:00AM-10:00PM</td>
                        <td>09:00AM-10:00PM</td>
                      </tr>
                      <tr>
                        <td>Wednesday</td>
                        <td>09:00AM-10:00PM</td>
                        <td>09:00AM-10:00PM</td>
                      </tr>
                      <tr>
                        <td>Thursday</td>
                        <td>09:00AM-10:00PM</td>
                        <td>09:00AM-10:00PM</td>
                      </tr>
                      <tr>
                        <td>Friday</td>
                        <td>09:00AM-10:00PM</td>
                        <td>09:00AM-10:00PM</td>
                      </tr>
                      <tr>
                        <td>Saturday</td>
                        <td>09:00AM-10:00PM</td>
                        <td>09:00AM-10:00PM</td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-md-5 mb-5">
                    <h3>
                      Download Croydon Food Station App & Order now on app
                    </h3>
                    <ul className="list-unstyled footer-link">
                      <li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.croydonfoodstation"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img
                            src={googleplaystore}
                            className="img-fluid about_img_1"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://apps.apple.com/in/app/croydon-food-station/id6477545869"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          <img
                            src={appleplaystore}
                            className="img-fluid about_img_1"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3 mb-5">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.2793112835816!2d-0.10138219999999999!3d51.3795444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487607831e0fafd9%3A0x3954e9298495662d!2sCroydon%20Food%20Station!5e0!3m2!1sen!2sus!4v1711079560758!5m2!1sen!2sus"
                    width="200"
                    height="200"
                    frameBorder="0"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <div className="block">
                    <button
                      style={{
                        fontSize: "1rem",
                        backgroundColor: "#0096EE",
                        padding: "0.5em 1em",
                        marginTop: "1em",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        width: "100%",
                        maxWidth: "200px",
                        boxSizing: "border-box",
                      }}
                      onClick={toggleFaq}
                    >
                      Faq
                    </button>
                  </div>
                  <div className="col-12 text-center">
                    <div className="block">
                      <button
                        style={{
                          fontSize: "1rem",
                          backgroundColor: "#0096EE",
                          padding: "0.5em 1em",
                          marginTop: "1em",
                          color: "white",
                          border: "none",
                          borderRadius: "5px",
                          width: "100%",
                          maxWidth: "200px",
                          boxSizing: "border-box",
                          marginBottom: "1em",
                        }}
                        onClick={toggleFeedback}
                      >
                        Feedback
                      </button>
                    </div>
                  </div>
                  <p>
                    <small className="block">
                      &copy; 2024{" "}
                      <strong className="text-black">
                        Croydon Food Station
                      </strong>{" "}
                      . All Rights Reserved.
                      <br /> Design by{" "}
                      <a href="https://www.datumailabs.com/">
                        Datum AI Labs
                      </a>{" "}
                      <br />
                      <a href="/TermsConditions">Terms and Conditions</a>
                      <br />
                      <a href="/privacy">Privacy</a>
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {isFaqOpen && (
        <div style={popupStyle}>
          <div style={popupInnerStyle}>
            <button style={closeButtonStyle} onClick={toggleFaq}>
              &times;
            </button>
            <Faq />
          </div>
        </div>
      )}
      {isFeedbackOpen && (
        <div style={popupStyle}>
          <div style={popupInnerStyle}>
            <button style={closeButtonStyle} onClick={toggleFeedback}>
              &times;
            </button>
            <Feedback userId={null} />
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
