import vegRice from "../assets/menuimages/fried rice and noodles/vegRice.jpeg";
import mixedRice from "../assets/menuimages/fried rice and noodles/mixedRice.jpg";
import mushroomFriedRice from "../assets/menuimages/fried rice and noodles/mushroomFriedRice.jpg";
import paneerFriedRice from "../assets/menuimages/fried rice and noodles/paneerFriedRice.jpg";
import vegNoodles from "../assets/menuimages/fried rice and noodles/vegNoodles.jpeg";
import mixedNoodles from "../assets/menuimages/fried rice and noodles/mixedNoodles.jpg";
import mushroomNoodles from "../assets/menuimages/fried rice and noodles/mushroomNoodles.jpg";
import paneerNoodles from "../assets/menuimages/fried rice and noodles/paneerNoodles.jpg";
import prawnRice from "../assets/menuimages/fried rice and noodles/prawnRice.jpg";
import chickenFriedRice from "../assets/menuimages/fried rice and noodles/chickenFriedRice.jpg";
import lambFriedRice from "../assets/menuimages/fried rice and noodles/lambFriedRice.jpg";
import splRice from "../assets/menuimages/fried rice and noodles/splRice.jpg";
import prawnNoodles from "../assets/menuimages/fried rice and noodles/prawnNoodles.jpg";
import chickenNoodles from "../assets/menuimages/fried rice and noodles/chickenNoodles.jpg";
import lambNoodles from "../assets/menuimages/fried rice and noodles/lambNoodles.jpg";
import FSspecialNoodles from "../assets/menuimages/fried rice and noodles/FSspecialNoodles.jpg";

export const VegFriedRice=[
    {
        category: "Veg Fried Rice",
        foodName: "Vegetable Rice",
        price: 6.25,
        description:
          "A delicious and satisfying meal option.",
          image: `url(${vegRice})`,
      },
      {
        category: "Veg Fried Rice",
        foodName: "Mushroom fried rice",
        price: 8.75,
        description:
          "Mushroom fried rice is a delicious dish made by stir-frying rice with mushrooms, vegetables, and savory sauces, resulting in a flavorful and satisfying meal option with a hint of earthy richness.",
          image: `url(${mushroomFriedRice})`,
      },
      {
        category: "Veg Fried Rice",
        foodName: "Paneer fried rice",
        price: 8.75,
        description:
          "Paneer fried rice is a flavorful and satisfying dish made with stir-fried rice, succulent paneer cubes, and a medley of vegetables, offering a delicious fusion of textures and flavors with a hint of Indian influence.",
          image: `url(${paneerFriedRice})`,
      },
      
]
export const VegNoodles=[
    {
        category: "Veg Noodles",
        foodName: "Vegetable Noodles",
        price: 6.25,
        description:
          "Flavorful and aromatic rice noodles stir-fried with assorted vegetables.",
          image: `url(${vegNoodles})`,
      }, 
      {
        category: "Veg Noodles",
        foodName: "Mushroom noodles",
        price: 8.75,
        description:
          "Mushroom noodles feature stir-fried noodles infused with earthy mushrooms, crisp vegetables, and aromatic seasonings, creating a savory and satisfying dish with rich umami flavors.",
          image: `url(${mushroomNoodles})`,
      }, 
      {
        category: "Veg Noodles",
        foodName: "Paneer Noodles",
        price: 8.75,
        description:
          "Paneer noodles combine stir-fried noodles with chunks of paneer (Indian cottage cheese), crisp vegetables, and a blend of spices, offering a flavorful and satisfying fusion of Indian and Chinese cuisines.",
          image: `url(${paneerNoodles})`,
      }, 
      
]
export const NonVegFriedRice=[
    {
        category: "Non-Veg Fried Rice",
        foodName: "Prawn Rice",
        price: 9.40,
        description:
          "A classic and satisfying meal option.",
          image: `url(${prawnRice})`,
      },
      {
        category: "Non-Veg Fried Rice",
        foodName: "Chicken fried Rice",
        price: 8.75,
        description:
          "A flavorful and satisfying meal option.",
          image: `url(${chickenFriedRice})`,
      },
      {
        category: "Non-Veg Fried Rice",
        foodName: "Lamb fried Rice",
        price: 10.00,
        description:
          "A hearty and satisfying meal option.",
          image: `url(${lambFriedRice})`,
      },
      {
        category: "Non-Veg Fried Rice",
        foodName: "Food Station Spl Rice",
        price: 11.25,
        description:
          "Chef's special meal featuring premium ingredients, aromatic spices, and exquisite flavors.",
          image: `url(${splRice})`,
      },
      {
        category: "Veg Fried Rice",
        foodName: "Mixed Rice",
        price: 9.40,
        description:
          " A wholesome and satisfying meal option.",
          image: `url(${mixedRice})`,
      },
]
export const NonVegNoodles=[
    {
        category: "Non-Veg Noodles",
        foodName: "Prawn Noodles",
        price: 9.40,
        description:
          "A classic and satisfying meal option.",
          image: `url(${prawnNoodles})`,
      },
      {
        category: "Non-Veg Noodles",
        foodName: "Chicken Noodles",
        price: 8.75,
        description:
          "Tender chicken pieces stir-fried with fragrant rice noodles and vegetables.",
          image: `url(${chickenNoodles})`,
      },
      {
        category: "Non-Veg Noodles",
        foodName: "Lamb Noodles",
        price: 10.00,
        description:
          "Flavorful and aromatic rice noodles stir-fried with tender lamb pieces and assorted vegetables",
          image: `url(${lambNoodles})`,
      },
      {
        category: "Non-Veg Noodles",
        foodName: "Food Station Spl Noodles",
        price: 11.25,
        description:
          "Chef's special rice noodle dish featuring premium ingredients, aromatic spices, and exquisite flavors.",
          image: `url(${FSspecialNoodles})`,
      },
      {
        category: "Veg Noodles",
        foodName: "Mixed Noodles",
        price: 9.40,
        description:
          "A flavorful and aromatic combination of rice noodles stir-fried with mixed vegetables.",
          image: `url(${mixedNoodles})`,
      }, 
]
