import plaindosa from "../assets/menuimages/dosa/plaindosa.jpg";
import kalDosa from "../assets/menuimages/dosa/kalDosa.jpg";
import masalaDosa from "../assets/menuimages/dosa/masalaDosa.jpg";
import gheeMasalaDosa from "../assets/menuimages/dosa/gheeMasalaDosa.jpg";
import gheeRoast from "../assets/menuimages/dosa/gheeRoast.jpeg";
import ravaDosa from "../assets/menuimages/dosa/ravaDosa.jpeg";
import ravaMasalaDosa from "../assets/menuimages/dosa/ravaMasalaDosa.jpg";
import ravaOnionDosa from "../assets/menuimages/dosa/ravaOnionDosa.jpg";
import onionDosa from "../assets/menuimages/dosa/onionDosa.jpg";
import onionUtthapam from "../assets/menuimages/dosa/onionUttappam.jpg";
import paneerDosa from "../assets/menuimages/dosa/paneerDosa.jpeg";
import paneerMasalaDosa from "../assets/menuimages/dosa/panneerMasalaDosa.jpeg";
import podiDosa from "../assets/menuimages/dosa/podiDosa.jpg";
import cheeseDosa from "../assets/menuimages/dosa/cheeseDosa.jpeg";
import cheeseMasalaDosa from "../assets/menuimages/dosa/cheeseMasalaDosa.jpeg";
import paperRoast from "../assets/menuimages/dosa/paperRoast.jpeg";
import podiMasalaDosa from "../assets/menuimages/dosa/podiMasalaDosa.jpg";
import mysoreMasalaDosa from "../assets/menuimages/dosa/mysoreMasalaDosa.jpg";
import eggdosa from "../assets/menuimages/dosa/eggDosa.jpeg";
import chickenDosa from "../assets/menuimages/dosa/chickenDosa.jpeg";
import lambDosa from "../assets/menuimages/dosa/lambDosa.jpg";
import chickenKariDosa from "../assets/menuimages/dosa/chickenKariDosa.jpg";
import muttonKariDosa from "../assets/menuimages/dosa/muttonKariDosa.jpg";

export const vegDosa = [
  {
    category: "Veg - Dosa",
    foodName: "Plain Dosa",
    price: 6.25,
    description:
      "Thin and crispy South Indian crepe made with fermented rice and lentil batter, a classic breakfast option served with chutney and sambar.",
    image: `url(${plaindosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Kal dosa(2No)",
    price: 7.50,
    description:
      "Soft and fluffy South Indian crepes served in pairs, a delightful breakfast option enjoyed with chutney and sambar.",
    image: `url(${kalDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Masala Dosa",
    price: 6.90,
    description:
      "Crispy and flavorful South Indian crepe filled with a spicy potato filling, a classic and satisfying breakfast option.",
    image: `url(${masalaDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Ghee Masala Dosa",
    price: 8.15,
    description:
      "Indulgent and flavorful masala dosa made with clarified butter (ghee) and filled with a spicy potato filling, a rich and satisfying meal option.",
    image: `url(${gheeMasalaDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Ghee Roast",
    price: 8.15,
    description:
      "Crispy and flavorful dosa made with clarified butter (ghee), a classic South Indian delicacy enjoyed with chutney and sambar.",
    image: `url(${gheeRoast})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Rava Dosa",
    price: 6.90,
    description:
      "Crispy and lacy dosa made with semolina (rava) and rice flour, a quick and easy breakfast option served with chutney and sambar.",
    image: `url(${ravaDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Rava Masala Dosa",
    price: 8.15,
    description:
      "Soft and spongy dosa made with semolina (rava) and fermented rice batter, a delicious and nutritious breakfast option.",
    image: `url(${ravaMasalaDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Rava Onion Dosa",
    price: 7.50,
    description:
      "Flavorful and crispy dosa made with semolina (rava) and onions, a delicious and aromatic breakfast option.",
    image: `url(${ravaOnionDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Onion Dosa",
    price: 6.90,
    description:
      "Crispy and flavorful dosa made with fermented rice and lentil batter, topped with onions, a classic South Indian delicacy.",
    image: `url(${onionDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Onion Uttappam",
    price: 6.90,
    description:
      "Soft and fluffy South Indian pancake made with fermented rice and lentil batter, topped with onions, a classic breakfast option.",
    image: `url(${onionUtthapam})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Panner Dosa",
    price: 6.90,
    description:
      "Flavorful and crispy dosa filled with a delicious paneer (Indian cottage cheese) filling, a delightful vegetarian option.",
    image: `url(${paneerDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Panner Masala Dosa",
    price: 8.15,
    description:
      "Crispy dosa filled with a flavorful paneer (Indian cottage cheese) masala, a delicious and satisfying vegetarian meal option.",
    image: `url(${paneerMasalaDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Podi Dosa",
    price: 6.25,
    description:
      "Spicy and flavorful dosa topped with a spicy and aromatic podi (powder), a classic South Indian delicacy.",
    image: `url(${podiDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Cheese Dosa",
    price: 6.90,
    description:
      "Indulgent and flavorful dosa filled with melted cheese, a rich and satisfying meal option loved by cheese enthusiasts.",
    image: `url(${cheeseDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Cheese Masala Dosa",
    price: 8.15,
    description:
      "Crispy dosa filled with a spicy potato filling and topped with melted cheese, a delicious and indulgent meal option.",
    image: `url(${cheeseMasalaDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Paper Roast",
    price: 6.90,
    description:
      "Thin and crispy dosa cooked to perfection, a classic South Indian delicacy enjoyed with chutney and sambar.",
    image: `url(${paperRoast})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Podi Masala Dosa",
    price: 8.15,
    description:
      "Crispy dosa filled with a spicy potato filling and topped with aromatic podi (powder), a flavorful and satisfying meal option.",
    image: `url(${podiMasalaDosa})`,
  },
  {
    category: "Veg - Dosa",
    foodName: "Mysore Masala Dosa",
    price: 7.50,
    description:
      "Flavorful and spicy dosa filled with a delicious potato filling and topped with a special Mysore chutney, a classic South Indian delicacy.",
    image: `url(${mysoreMasalaDosa})`,
  },
];

export const nonVegDosa = [
  
  {
    category: "Non-Veg Dosa",
    foodName: "Chicken Dosa",
    price: 7.50,
    description:
      "Spicy and flavorful dosa filled with a delicious chicken filling, a hearty and satisfying meal option for meat lovers.",
    image: `url(${chickenDosa})`,
  },
  {
    category: "Non-Veg Dosa",
    foodName: "Lamb Dosa",
    price: 8.75,
    description:
      "Mouthwatering dosa filled with a flavorful and aromatic lamb filling, a delicious and indulgent meal option.",
    image: `url(${lambDosa})`,
  },
  {
    category: "Non-Veg Dosa",
    foodName: "Chicken Kari Dosa",
    price: 8.75,
    description:
      "A South Indian delicacy featuring a thin, crispy dosa filled with a savory chicken curry, packed with spices and flavors.",
    image: `url(${chickenKariDosa})`,
  },
  {
    category: "Non-Veg Dosa",
    foodName: "lamb Kari Dosa",
    price: 10.00,
    description:
      "Similar to Chicken Kari Dosa, this version contains tender lamb cooked in a rich, aromatic curry, wrapped inside a dosa for a hearty meal.",
    image: `url(${muttonKariDosa})`,
  },
];
