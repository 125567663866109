import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Home.js";
import Privacy from "./components/Privacy.js";
import TermsConditions from "./components/TermsConditions.js";
import GoToAppStore from "./components/GotoAppStore.js";
import Feedback from "./components/Feedback.js";
import Faq from "./components/Faq.js";

const App = () => (
  <Router>
    <Routes>
      {/* Route for Home page */}
      <Route path="/" element={<Home />} />
      {/* Route for Terms amd Condition page */}
      <Route path="/TermsConditions" element={<TermsConditions />} />
      {/* Route for Terms and page */}
      <Route path="/privacy" element={<Privacy />} />
      {/* Route for QR App Store page */}
      <Route path="/GoToAppStore" element={<GoToAppStore />} />
      {/* Route for Faq */}
      <Route path="/faq" element={<Faq />} />
      {/* Route for Feedback */}
      <Route
        path="/feedback/:userId?"
        element={
          <div style={{ padding: "10px" }}>
            <Feedback />
          </div>
        }
      />
    </Routes>
  </Router>
);

export default App;
