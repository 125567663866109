import "./styles/style.css";
import Header from "./components/Header";
import SliderCarousel from "./components/SliderCarousel";
import TheRestaurant from "./components/TheRestaurant";

import Contact from "./components/Contact";
import Footer from "./components/Footer";
import MenuTabs from "./components/MenuTabs";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <Header />
      <SliderCarousel />
      <TheRestaurant />      
      <MenuTabs />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;
