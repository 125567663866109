import vegThali from "../assets/menuimages/extras/vegThali.jpg";
import nonVegThali from "../assets/menuimages/extras/nonVegThali.jpg";
import fishThali from "../assets/menuimages/extras/FishThali.jpg";
import papadam from "../assets/menuimages/extras/papadam.jpg";
import miniTiffin from "../assets/menuimages/extras/miniTiffin.jpeg";

export const thali=[
    {
        category: "Thali Friday",
        foodName: "Veg Thali (10 items)",
        price: 12.50,
        description:
          "A traditional Indian platter featuring a variety of vegetarian dishes, including curries, rice, bread, and condiments.",
        image: `url(${vegThali})`,

    },
    {
        category: "Thali Friday",
        foodName: " Non Veg Thali (11 items)",
        price: 16.25,
        description:
          "A lavish spread of non-vegetarian dishes served in a thali, showcasing the rich diversity of Indian cuisine.",
        image: `url(${nonVegThali})`,
    },
    {
      category: "Thali Friday",
      foodName: " Fish Thali (11 items)",
      price: 22.50,
      description:
        "A fish thali is a traditional Indian meal consisting of a variety of fish dishes served on a platter alongside accompaniments like rice, chapati (flatbread), dal (lentil curry), vegetable dishes, pickles, and desserts, offering a diverse and satisfying culinary experience.",
      image: `url(${fishThali})`,
  },
]
export const starter=[
    {
        category: "Starter Friday",
        foodName: "Papadam (2 Nos)",
        price: 2.50,
        description:
          "Crispy and crunchy papadums, a popular accompaniment to Indian meals.",
        image: `url(${papadam})`,
    },
]

export const Tiffin=[
    {
        category: "Tifin Fri Sat Sun ",
        foodName: "Mini Tiffin (Fri /Sat/ Sun)",
        price: 11.25,
        description:
          "A special mini tiffin menu available on Fridays, Saturdays, and Sundays, featuring a variety of delicious and wholesome South Indian breakfast items.",
        image: `url(${miniTiffin})`,
    },
]