import idly from "../assets/menuimages/tiffin/idly.jpg";
import pooriMasala from "../assets/menuimages/tiffin/pooriMasala.jpg";
import sambarIdly from "../assets/menuimages/tiffin/sambarIdly.jpeg";
import pongal from "../assets/menuimages/tiffin/pongal.jpeg";
import upma from "../assets/menuimages/tiffin/upma.jpg";
import kichadi from "../assets/menuimages/tiffin/kichadi.jpg";
import miniIdly from "../assets/menuimages/tiffin/miniIdly.jpg";


export const tiffin=[
    {
        category: "Tiffin",
        foodName: "Idly(3 Nos)",
        price: 5.65,
        description:
          "Soft and fluffy South Indian steamed rice cakes served in threes, a classic breakfast option enjoyed with chutney and sambar.",
        image: `url(${idly})`,
    },
    {
        category: "Tiffin",
        foodName: "Poori Masala(2 Nos)",
        price: 7.50,
        description:
          "Fluffy and deep-fried Indian bread served with a flavorful potato masala, a classic and satisfying breakfast option.",
        image: `url(${pooriMasala})`,
    },
    {
        category: "Tiffin",
        foodName: "Mini Idly",
        price: 5.00,
        description:"Mini idly refers to small, bite-sized versions of the traditional South Indian idly, which are steamed rice cakes made from fermented rice and lentil batter. They are typically served with chutney and sambar, making them a popular breakfast or snack option in South India.",
        image: `url(${miniIdly})`,
    },
    {
        category: "Tiffin",
        foodName: "Sambar Idly(2 Nos)",
        price: 5.00,
        description:
          "Soft and fluffy idlis served with flavorful sambar, a classic South Indian breakfast enjoyed by many.",
        image: `url(${sambarIdly})`,
    },
    {
        category: "Tiffin",
        foodName: "Pongal/Upma/Kichadi",
        price: 5.65,
        description:
          "A traditional South Indian breakfast consisting of pongal (savory rice porridge), upma (semolina porridge), and kichadi (spiced lentil and rice dish), served with chutney and sambar.",
        image: `url(${pongal})`,
    },
    {
      category: "Tiffin",
      foodName: "Upma",
      price: 5.65,
      description:"A comforting South Indian dish, prepared with semolina, seasoned with spices, and garnished with nuts or coconut.",
      image: `url(${upma})`,
  },
  {
    category: "Tiffin",
    foodName: "Kichadi",
    price: 5.65,
    description:"A comforting South Indian dish blending rice, lentils, and vegetables, seasoned with aromatic spices, perfect for a nourishing and satisfying meal.",
    image: `url(${kichadi})`,
},
]