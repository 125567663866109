import mushroomMasala from "../assets/menuimages/masala/mushroomMasala.jpg";
import paneerButterMasala from "../assets/menuimages/masala/paneerButterMasala.jpg";
import cauliflowerMasala from "../assets/menuimages/masala/cauliflowerMasala.jpg";
import cauliflowerPotatoMasala from "../assets/menuimages/masala/cauliflowerPotatoMasala.jpg";
import chennaMasalaDry from "../assets/menuimages/masala/chennaMasalaDry.jpg";
import spinachPotatoMasala from "../assets/menuimages/masala/spinachPotatoMasala.jpg";
import spinachDhall from "../assets/menuimages/masala/spinachDhall.jpg";
import spinachMasala from "../assets/menuimages/masala/spinachMasala.jpg";
import bombayAlooMasala from "../assets/menuimages/masala/bombayAlooMasala.jpg";
import chickenMasala from "../assets/menuimages/masala/chickenMasala.jpg";
import chickenButterMasala from "../assets/menuimages/masala/chickenButterMasala.jpg";




export const vegMasala=[
    {
    category: "Veg-Masalaa",
    foodName: "Panner Butter Masala",
    price: 6.90,
    description:
      "Creamy and flavorful paneer (Indian cottage cheese) curry cooked with butter, tomatoes, and aromatic spices, a rich and indulgent vegetarian delicacy.",
    image: `url(${paneerButterMasala})`,
    },
    {
        category: "Veg-Masalaa",
        foodName: "Cauliflower Masala",
        price: 6.90,
        description:
          "Tender cauliflower florets cooked in a flavorful and aromatic gravy made with onions, tomatoes, and aromatic spices, a delicious vegetarian dish.",
        image: `url(${cauliflowerMasala})`,
    },
    {
        category: "Veg-Masalaa",
        foodName: "Mushroom Masala",
        price: 6.90,
        description:
          "Flavorful and aromatic mushroom curry cooked with onions, tomatoes, and aromatic spices, a hearty and satisfying vegetarian dish.",
        image: `url(${mushroomMasala})`,
    },
    {
        category: "Veg-Masalaa",
        foodName: "Cauliflower Potato Masala",
        price: 6.90,
        description:
          "A delicious and hearty vegetarian curry made with cauliflower, potatoes, and a blend of aromatic spices, a comforting and satisfying dish.",
        image: `url(${cauliflowerPotatoMasala})`,
    },
    {
        category: "Veg-Masalaa",
        foodName: "Chenna Masala Dry",
        price: 5.00,
        description:
          "Soft and flavorful paneer (Indian cottage cheese) cooked with onions, tomatoes, and aromatic spices, a delicious and satisfying vegetarian dish.",
        image: `url(${chennaMasalaDry})`,
    },
    {
        category: "Veg-Masalaa",
        foodName: "Spinach - Potato Masala",
        price:6.90,
        description:
          "A nutritious and flavorful vegetarian curry made with spinach, potatoes, and aromatic spices, a wholesome and satisfying dish.",
        image: `url(${spinachPotatoMasala})`,
    },
    {
      category: "Veg-Masalaa",
      foodName: "Spinach Dhall",
      price:6.25,
      description:"Spinach dal is a flavorful South Indian dish where lentils are cooked with fresh spinach and spices, creating a nutritious and delicious meal.",
      image: `url(${spinachDhall})`,
  },
  {
    category: "Veg-Masalaa",
    foodName: "Spinach Masala",
    price:5.00,
    description:"Spinach masala is a savory Indian dish featuring spinach leaves cooked with a blend of aromatic spices and creamy ingredients, creating a flavorful vegetarian curry.",
    image: `url(${spinachMasala})`,
},
    {
        category: "Veg-Masalaa",
        foodName: "Bombay Aloo Masala",
        price:6.25,
        description:
          "Flavorful and aromatic potato curry cooked with onions, tomatoes, and aromatic spices, a classic and comforting vegetarian dish.",
        image: `url(${bombayAlooMasala})`,
    },
]

export const NonVegMasala=[
    {
        category: "Non-Veg Masalaa",
        foodName: "Chicken Masala",
        price:7.50,
        description:
          "Tender chicken pieces cooked in a rich and flavorful gravy made with onions, tomatoes, and aromatic spices, a classic and satisfying dish.",
        image: `url(${chickenMasala})`,
    },
    {
        category: "Non-Veg Masalaa",
        foodName: "Chicken Butter Masala",
        price:7.50,
        description:
          "Creamy and flavorful chicken curry cooked with butter, tomatoes, and aromatic spices, a rich and indulgent delicacy loved by many.",
        image: `url(${chickenButterMasala})`,
    },
]