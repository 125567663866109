import React, { Fragment } from "react";

// TermsConditions function component with style classes and content
const TermsConditions = () => {
  return (
    <Fragment>
      <div className="space-top space-extra-bottom">
        <div className="container">
          <div className="col-12">
            <p className="py-2 color-dark">
              <h4>Terms and Conditions</h4>
              <p className="py-2 color-dark">
                Welcome to Croydon Food Station Online Food Ordering App! We are
                thrilled to have you join us and explore our delicious
                offerings. Please take a moment to review the following Terms
                and Conditions, as they govern your use of our app and the
                services we provide.
              </p>
              <h5>1. Acceptance of Terms</h5>
              <p>
                1.1. By using our Food Ordering App, you agree to comply with
                and be bound by these Terms and Conditions. If you do not agree,
                please refrain from using the app.
              </p>
              <p>
                1.2. You must be at least 18 years old to use our app. If you
                are under 18, please use the app with the supervision of a
                parent or guardian.
              </p>

              <h5>2. User Responsibilities</h5>
              <p>
                2.1. Provide accurate, current, and complete information during
                the registration process.2.2. Maintain the confidentiality of
                your account and password, and ensure restricted access to your
                device.
              </p>
              <p>
                2.3. Accept responsibility for all activities that occur under
                your account.
              </p>

              <h5>3. Services Provided</h5>
              <p>
                3.1. Our app serves as a platform for you to place food orders
                from our restaurant.
              </p>
              <p>
                3.2. We are committed to providing quality food, but please note
                that we do not own or control the delivery process.
              </p>

              <h5>4. Ordering and Payment</h5>
              <p>
                4.1. Place orders through our app for delivery or pickup.4.2.
                Payments for orders must be made through the app using the
                available payment methods.
              </p>
              <p>
                4.3. Menu item prices are subject to change without prior
                notice.
              </p>

              <h5>5. Delivery</h5>
              <p>5.1. Delivery times are estimated and may vary.</p>
              <p>
                5.2. Delivery fees, if applicable, will be displayed before you
                place your order.
              </p>

              <h5>6. Cancellation and Refunds</h5>
              <p>
                6.1. Cancel orders before they are accepted by our restaurant.
              </p>
              <p>
                6.2. Request refunds for cancelled orders or quality issues
                within a reasonable timeframe.
              </p>

              <h5>7. Intellectual Property</h5>
              <p>
                7.1. Our app and its content are our property and are protected
                by copyright and other laws.
              </p>

              <h5>8. Privacy</h5>
              <p>
                8.1. Your privacy is important to us. Please review our{" "}
                <a href="/privacy">Privacy Policy</a> for more details.
              </p>

              <h5>9. Disclaimer of Warranties</h5>
              <p>
                9.1. Our app is provided "as is" without warranties of any kind.
              </p>
              <p>
                9.2. We do not guarantee uninterrupted, secure, or error-free
                operation.
              </p>

              <h5>10. Limitation of Liability</h5>
              <p>
                10.1. We are not liable for indirect, incidental, or
                consequential damages arising from app use.
              </p>

              <h5>11. Governing Law</h5>
              <p>
                11.1. These Terms and Conditions are governed by UK
                jurisdiction's laws.
              </p>
              <p>
                {" "}
                11.2. Disputes will be subject to the exclusive jurisdiction of
                courts in UK Jurisdiction.
              </p>
              <h5>12. Changes to Terms and Conditions</h5>
              <p>
                12.1. We may modify these Terms and Conditions, and your
                continued use of the app constitutes acceptance of the changes.
              </p>
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TermsConditions;
