import whiteRice from "../assets/menuimages/rice/whiteRice.jpg";
import lemonRice  from "../assets/menuimages/rice/lemonRice.jpg";
import sambarRice from "../assets/menuimages/rice/sambarRice.jpg";
import curdRice from "../assets/menuimages/rice/curdRice.jpg";
import TomatoRice from "../assets/menuimages/rice/tomatoRice.jpg";
export const rice=[
    {
        category: "Rice",
        foodName: "White Rice(plain)",
        price: 2.50,
        description:
          "Simple and comforting plain white rice, a staple in many meals.",
        image: `url(${whiteRice})`,
    },
    {
        category: "Rice",
        foodName: "Lemon Rice",
        price: 5.00,
        description:
          "Tangy and aromatic rice dish flavored with lemon juice and tempered with spices.",
        image: `url(${lemonRice})`,
    },
    {
        category: "Rice",
        foodName: "Sambar Rice",
        price: 5.65,
        description:
          "A flavorful South Indian rice dish cooked with sambar masala, vegetables, and rice.",
        image: `url(${sambarRice})`,
    },
    {
        category: "Rice",
        foodName: "Curd Rice(Yoghurt)",
        price: 5.00,
        description:
          "Creamy and refreshing rice mixed with yogurt and seasoned with spices.",
        image: `url(${curdRice})`,
    },
    {
        category: "Rice",
        foodName: "Tomato Rice",
        price: 5.65 ,
        description:
          "A zesty rice dish cooked with tomatoes, spices, and aromatic herbs.",
        image: `url(${TomatoRice})`,
    },
]