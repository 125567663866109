import React, { useState, useEffect } from "react";
import axios from "axios";
import downarrow from "../assets/images/downarrow.png";
import { Image } from "react-bootstrap";
import logo from "../assets/images/logo-small.png";

const Faq = () => {
  const [faqs, setFaqs] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [expandAll, setExpandAll] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/faq`);
        setFaqs(response.data.data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [API_URL]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleExpandAllChange = (event) => {
    setExpandAll(event.target.checked);
    setActiveIndex(event.target.checked ? -1 : null);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxHeight: "90vh",
        gap: "0.1em",
        padding: "10px",
        boxSizing: "border-box",
        overflowY: "auto",
      }}
    >
      <Image
        src={logo}
        alt="croydon food station"
        style={{ maxWidth: "100px", maxHeight: "100px" }}
      />
      <h3
        style={{
          fontSize: isMobile ? "1.5rem" : "2rem",
          marginBottom: "20px",
          color: "#333",
          textAlign: isMobile ? "center" : "left",
        }}
      >
        Frequently asked questions
      </h3>
      <div
        style={{
          width: isMobile ? "100%" : "80%",
          marginBottom: "20px",
          textAlign: "left",
        }}
      >
        <label style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            checked={expandAll}
            onChange={handleExpandAllChange}
            style={{ marginRight: "10px" }}
          />
          Expand All
        </label>
      </div>
      <div
        style={{
          width: isMobile ? "100%" : "80%",
        }}
      >
        {faqs.map((faq, index) => (
          <div key={index} style={{ marginBottom: "15px" }}>
            <button
              style={{
                width: "100%",
                padding: isMobile ? "10px" : "15px",
                backgroundColor:
                  activeIndex === index || expandAll ? "#f9f9f9" : "#fff",
                border: "1px solid #ddd",
                borderRadius: "5px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                transition: "background-color 0.3s ease",
                outline: "none",
                boxSizing: "border-box",
              }}
              onClick={() => toggleAccordion(index)}
            >
              <span
                style={{
                  fontSize: isMobile ? "0.9rem" : "1rem",
                  fontWeight: "bold",
                  color: "#555",
                  textAlign: "left",
                  flex: 1,
                }}
              >
                {faq.prompt}
              </span>
              <img
                src={downarrow}
                alt="Toggle"
                style={{
                  width: "20px",
                  height: "20px",
                  transform:
                    activeIndex === index || expandAll
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                  marginLeft: "10px",
                }}
              />
            </button>
            <div
              style={{
                maxHeight: activeIndex === index || expandAll ? "500px" : "0",
                overflow: "hidden",
                transition: "max-height 0.3s ease, padding 0.3s ease",
                padding: activeIndex === index || expandAll ? "15px" : "0 15px",
                backgroundColor:
                  activeIndex === index || expandAll ? "#f9f9f9" : "#fff",
                border:
                  activeIndex === index || expandAll
                    ? "1px solid #ddd"
                    : "none",
                borderRadius: "5px",
                marginTop: activeIndex === index || expandAll ? "5px" : "0",
                color: "#666",
                fontSize: "0.95rem",
              }}
            >
              {(activeIndex === index || expandAll) && faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faq;
