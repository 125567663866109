import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import about1 from "../assets/images/Juice.jpg";
import dishes1 from "../assets/images/B4.jpg";
import dishes3 from "../assets/images/poster3.jpg";
import title from "../assets/images/croydon.jpg"

const TheRestaurant = () => {
  return (
    <section id="home" className="mt-5">
      <Container fluid>
        <Row className="mb-5 justify-content-center" data-aos="fade">
          <Col md={7} className="text-center heading-wrap" data-aos="fade-up">
            <h2><img src={title} className="img-fluid about_img_1"  alt="dishes"/></h2>
            <p data-aos="fade-up" data-aos-delay="100">
               With our mouth-watering meals, super fast Restaurant and takeway service, we intend to create magic for all our customers. We assure you that every single member of our staff will give you finest food experience which will undoubtedly entice you to come back for more. We are committed to providing you with the best culinary experience and also keep maintaining the highest hygiene standards. Great food and limited time special offers are always available at Food Station, Croydon. Feel free to browse through our brand new website and place an online order.
            </p>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col lg={4}>
            <img
              src={dishes1}
              alt="dishes"
              className="img-fluid about_img_1"
              data-aos="fade"
              data-aos-delay="200"
            />
          </Col>
          <Col lg={4}>
            <img
              src={about1}
              alt="dishes"
              className="img-fluid about_img_1"
              data-aos="fade"
              data-aos-delay="300"
            />
            
          </Col>
          <Col lg={4}>
            <img
              src={dishes3}
              alt="dishes"
              className="img-fluid about_img_1"
              data-aos="fade"
              data-aos-delay="500"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TheRestaurant;
