import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Image } from "react-bootstrap";
import logo from "../assets/images/logo-small.png";

const Feedback = () => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const { userId } = useParams();

  const handleRatingChange = (ratingValue) => {
    setRating(ratingValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const payload = {
        userId,
        rating,
        feedback,
      };

      if (!userId && email !== "") {
        payload.email = email;
      }

      const response = await axios.post(`${API_URL}/api/rating/add`, payload);

      setMessage(response.data.message);
      setSuccess(response.data.success);

      if (response.data.success) {
        setRating(0);
        setFeedback("");
        setEmail("");
      }
    } catch (error) {
      setMessage("Error submitting feedback.");
      setSuccess(false);
    }
  };

  const getEmoji = (ratingValue) => {
    const emojis = ["😡", "😕", "😐", "😊", "😍"];
    return emojis[ratingValue - 1] || "😐";
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "85vh",
        gap: "0.1em",
        padding: "1em",
        boxSizing: "border-box",
      }}
    >
      <Image
        src={logo}
        alt="croydon food station"
        style={{ maxWidth: "100px", maxHeight: "100px" }}
      />
      <h3 style={{ fontSize: "1.5rem", color: "#333" }}>Share your Feedback and Rate us</h3>
      <p style={{ opacity: "70%", textAlign: "center", fontSize: "14px" }}>
        Please check the fields marked with *
      </p>
      <form
        onSubmit={handleSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          maxWidth: "500px",
          boxSizing: "border-box",
        }}
      >
        <label style={{ textAlign: "center", width: "100%" }}>
          How was your overall experience with the service today? *
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "15px 0",
            width: "100%",
          }}
        >
          {[1, 2, 3, 4, 5].map((ratingValue) => (
            <span
              key={ratingValue}
              onClick={() => handleRatingChange(ratingValue)}
              style={{
                fontSize: "2rem",
                cursor: "pointer",
                color: rating >= ratingValue ? "gold" : "grey",
                transform: rating === ratingValue ? "scale(1.3)" : "scale(1)",
                transition: "transform 0.2s",
                padding: "0 0.3em",
              }}
            >
              {getEmoji(ratingValue)}
            </span>
          ))}
        </div>
        <label style={{ textAlign: "center", width: "100%" }}>
          Please tell us more about why you have given that rating *
        </label>
        <div style={{ width: "100%" }}>
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            required
            style={{
              margin: "0.5em 0",
              padding: "0.5em",
              width: "100%",
              minHeight: "70px",
              boxSizing: "border-box",
              resize: "vertical",
            }}
          ></textarea>
        </div>
        {!userId && (
          <>
            <label style={{ textAlign: "center", width: "100%" }}>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                margin: "0.5em 0",
                padding: "0.5em",
                width: "100%",
                boxSizing: "border-box",
              }}
            />
          </>
        )}
        <button
          type="submit"
          style={{
            fontSize: "1rem",
            backgroundColor: "#0096EE",
            padding: "0.5em 1em",
            marginTop: "1em",
            color: "white",
            border: "none",
            borderRadius: "5px",
            width: "100%",
            maxWidth: "200px",
            boxSizing: "border-box",
          }}
        >
          Submit
        </button>
      </form>
      {message && (
        <div
          style={{
            color: success ? "green" : "red",
            marginTop: "1em",
            textAlign: "center",
          }}
        >
          {message}
        </div>
      )}
      <style jsx>{`
        @media (max-width: 600px) {
          h1 {
            font-size: 1.25rem;
          }
          p {
            font-size: 12px;
          }
          textarea {
            min-height: 50px;
          }
          button {
            font-size: 0.875rem;
            padding: 0.5em;
            max-width: 150px;
          }
        }
      `}</style>
    </div>
  );
};

export default Feedback;
