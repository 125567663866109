import parotta from "../assets/menuimages/breads/parotta.jpg";
import chapathi from "../assets/menuimages/breads/chapathi.jpg";
import alooParota from "../assets/menuimages/breads/alooParota.jpg";
import veechuRoti from "../assets/menuimages/breads/veechuRoti.jpg";


export const bread=[
    {
        category: "Breads",
        foodName: "Parota(2 No)",
        price: 3.75,
        description:
          "Flaky and layered Indian bread served in pairs, a delightful accompaniment to curries and gravies.",
        image: `url(${parotta})`,
      },
      {
        category: "Breads",
        foodName: "Chappathi(2 No)",
        price: 3.75,
        description:
          "Soft and fluffy Indian flatbread served in pairs, a versatile and comforting staple enjoyed with various curries and accompaniments.",
        image: `url(${chapathi})`,
      },
      {
        category: "Breads",
        foodName: "Aloo Parota(1 No)",
        price: 5.65,
        description:
          "Flaky and delicious Indian bread filled with spicy potato filling, a flavorful and satisfying meal option.",
        image: `url(${alooParota})`,
      },
      {
        category: "Breads",
        foodName: "Veechu Roti",
        price: 2.50,
        description:
          "Crispy and flaky Indian bread made with whole wheat flour, a delicious and satisfying accompaniment to curries and gravies.",
        image: `url(${veechuRoti})`,
      },
]