// veg curry

import dhallCurry from "../assets/menuimages/curry/dhallCurry.jpg";
import vegetableCurry from "../assets/menuimages/curry/vegetableCurry.jpg";
import chennaCurry from "../assets/menuimages/curry/chennaCurry.jpg";
import sambar from "../assets/menuimages/curry/sambar.jpg";
import kadaiPaneerMasala from "../assets/menuimages/curry/kadaiPannerMasala.jpg";
import yennaiKattirikkai from "../assets/menuimages/curry/yennaiKattirikkai.jpg";
import eggCurry from "../assets/menuimages/curry/eggCurry.jpg";

// non - veg curry

import chickenCurry from "../assets/menuimages/curry/chickenCurry.jpg";
import chickenMadras from "../assets/menuimages/curry/chickenMadras.jpg";
import chickenVindaloo from "../assets/menuimages/curry/chickenVindaloo.jpg";
import chickenKorma from "../assets/menuimages/curry/chickenKorma.jpg";
import kadaiChickenMasala from "../assets/menuimages/curry/kadaiChickenMasala.jpg";
import chickenChettinad from "../assets/menuimages/curry/chickenChettinad.jpeg";
import muttonChettinad from "../assets/menuimages/curry/muttonChettinad.jpg";
import lambCurry from "../assets/menuimages/curry/lambCurry.jpeg";
import lambMadras from "../assets/menuimages/curry/lambMadras.jpg";
import lambVindaloo from "../assets/menuimages/curry/lambVindaloo.jpeg";
import lambKorma from "../assets/menuimages/curry/lambKorma.jpeg";
import fishCurry from "../assets/menuimages/curry/fishCurry.jpeg";
import prawnCurry from "../assets/menuimages/curry/prawnCurry.jpeg";
import kanavaCurry from "../assets/menuimages/curry/kanavaCurry.jpg";
import crabCurry from "../assets/menuimages/curry/crabCurry.jpg";
import naatuKoliCurry from "../assets/menuimages/curry/naatuKoliCurry.jpg";
import kaadaiCurry from "../assets/menuimages/curry/kaadaiCurry.jpg";

export const Vegcurry=[
    {
        category: "Veg Curry",
        foodName: "Dhall Curry",
        price: 5.00,
        description:
          "Creamy and flavorful lentil curry cooked with aromatic spices and herbs, a comforting and nutritious dish.",
        image: `url(${dhallCurry})`,
    },
    {
        category: "Veg Curry",
        foodName: "Vegetable Curry",
        price: 5.00,
        description:
          "Aromatic and flavorful curry made with assorted vegetables, spices, and coconut milk, a vegetarian delight.",
        image: `url(${vegetableCurry})`,
    },
    {
        category: "Veg Curry",
        foodName: "Chenna Curry",
        price: 5.00,
        description:
          "A flavorful and aromatic curry made with paneer (Indian cottage cheese), tomatoes, onions, and a blend of spices, a rich and indulgent dish.",
        image: `url(${chennaCurry})`,
    },
    {
        category: "Veg Curry",
        foodName: "Sambar",
        price: 5.65,
        description:
          "A traditional South Indian lentil stew made with toor dal, vegetables, tamarind, and spices, served as a side dish with rice or idli.",
        image: `url(${sambar})`,
    },
    {
        category: "Veg Curry",
        foodName: "Kadai Panner Masala",
        price: 7.50,
        description:
          "Soft and creamy paneer cubes cooked with bell peppers, onions, and aromatic spices in a kadai (Indian wok), a flavorful vegetarian delicacy.",
        image: `url(${kadaiPaneerMasala})`,
    },
    {
        category: "Veg Curry",
        foodName: "Yennai Kattirikkai",
        price: 8.15,
        description:
          "Tender baby eggplants cooked in a spicy and tangy tamarind gravy, a flavorful and aromatic South Indian dish.",
        image: `url(${yennaiKattirikkai})`,
    },
    {
        category: "Veg Curry",
        foodName: "vada Curry",
        price: 7.50,
        description:"Vada curry is a South Indian dish consisting of spicy lentil fritters cooked in a flavorful gravy.",
        image: `url(${eggCurry})`,
    },
]

export const NonVegcurry=[
    {
        category: "Veg Curry",
        foodName: "Chicken Curry",
        price: 6.90,
        description:
          "Tender chicken pieces cooked in a rich and flavorful gravy made with onions, tomatoes, and aromatic spices, a classic Indian dish",
        image: `url(${chickenCurry})`,
    },
    {
        category: "Veg Curry",
        foodName: "Chicken Madras",
        price: 8.15,
        description:
          "Spicy and aromatic chicken curry cooked with onions, tomatoes, and a blend of South Indian spices, a flavorful and indulgent dish.",
        image: `url(${chickenMadras})`,
    },
    {
        category: "Veg Curry",
        foodName: "Chicken Vindaloo",
        price: 8.75,
        description:
          "A spicy and tangy chicken curry made with vinegar, garlic, and aromatic spices, a popular Goan delicacy known for its bold flavors.",
        image: `url(${chickenVindaloo})`,
    },
    {
        category: "Veg Curry",
        foodName: "Chicken Korma",
        price: 7.50,
        description:
          "Tender chicken pieces cooked in a creamy and aromatic gravy made with cashews, onions, and spices, a rich and indulgent dish.",
        image: `url(${chickenKorma})`,
    },
    {
        category: "Veg Curry",
        foodName: "Kadai Chicken Masala",
        price: 8.15,
        description:
          "Tender chicken pieces cooked in a rich and flavorful gravy made with onions, tomatoes, and aromatic spices, a classic Indian dish",
        image: `url(${kadaiChickenMasala})`,
    },
    {
        category: "Veg Curry",
        foodName: "Chicken Chettinad",
        price: 8.75,
        description:
          "Spicy and aromatic chicken curry made with a unique blend of Chettinad spices, a classic South Indian delicacy known for its bold flavors.",
        image: `url(${chickenChettinad})`,
    },
    {
        category: "Veg Curry",
        foodName: "Mutton Chettinad",
        price: 10.00,
        description:
          "Tender mutton pieces cooked in a spicy and aromatic Chettinad masala, a flavorful and indulgent South Indian delicacy.",
        image: `url(${muttonChettinad})`,
    },
    {
        category: "Veg Curry",
        foodName: "Lamb Curry",
        price: 8.75,
        description:
          "Succulent lamb pieces cooked in a rich and flavorful gravy made with onions, tomatoes, and aromatic spices, a hearty and satisfying dish.",
        image: `url(${lambCurry})`,
    },
    {
        category: "Veg Curry",
        foodName: "Lamb Madras",
        price: 9.40,
        description:
          "A spicy and aromatic lamb curry made with a blend of South Indian spices, onions, tomatoes, and coconut milk, a classic South Indian delicacy.",
        image: `url(${lambMadras})`,
    },
    {
        category: "Veg Curry",
        foodName: "Lamb Vindaloo",
        price: 9.40,
        description:
          "A tangy and spicy lamb curry made with vinegar, garlic, and aromatic spices, a popular Goan delicacy known for its bold flavors.",
        image: `url(${lambVindaloo})`,
    },
    {
        category: "Veg Curry",
        foodName: "Lamb Korma",
        price: 9.40,
        description:
          "Tender lamb pieces cooked in a creamy and aromatic gravy made with cashews, onions, and spices, a rich and indulgent dish.",
        image: `url(${lambKorma})`,
    },
    {
        category: "Veg Curry",
        foodName: "Fish Curry",
        price: 8.15,
        description:
          "Fresh and tender fish fillets cooked in a tangy and flavorful gravy made with onions, tomatoes, and aromatic spices, a classic seafood delicacy.",
        image: `url(${fishCurry})`,
    },
    {
        category: "Veg Curry",
        foodName: "Prawn Curry",
        price: 8.15,
        description:
          "Succulent prawns cooked in a flavorful and aromatic gravy made with onions, tomatoes, and a blend of spices, a seafood lover's delight.",
        image: `url(${prawnCurry})`,
    },
    {
      category: "Veg Curry",
      foodName: "Kanava curry",
      price: 10.00,
      description:"Kanava curry is a savory South Indian dish made with tender pieces of squid cooked in a rich, aromatic gravy infused with spices and coconut milk.",
      image: `url(${kanavaCurry})`,
    },
    {
    category: "Veg Curry",
    foodName: "Crab Curry",
    price: 8.75,
    description:"Crab curry is a delectable seafood dish where crab pieces are cooked in a flavorful sauce made with spices, coconut milk, and sometimes tomatoes or tamarind for a tangy touch.",
    image: `url(${crabCurry})`,
    },{
  category: "Veg Curry",
  foodName: "Naatu koli Curry",
  price: 9.40,
  description:"Naatu koli curry is a traditional South Indian dish made with free-range chicken cooked in a fragrant blend of spices, coconut, and tomatoes, resulting in a rich and flavorful curry.",
  image: `url(${naatuKoliCurry})`,
  },{
  category: "Veg Curry",
  foodName: "Kaadai Curry",
  price: 8.15,
  description:"Kaadai curry is a delicious South Indian dish featuring quail cooked in a spicy and aromatic gravy made with a blend of traditional spices, coconut, and tomatoes, offering a unique and flavorful taste.",
  image: `url(${kaadaiCurry})`,
},
]