import React from "react";
import hero1 from "../assets/images/B1.jpg";
import hero2 from "../assets/images/parottaChickenCurry.jpg";
import hero3 from "../assets/images/eggCurry.jpg";
import Carousel from "react-bootstrap/Carousel";

const SliderCarousel = () => {
  return (
    <div className="slider-wrap">
      <section className="home-slider owl-carousel">
        <Carousel controls={false} variant="dark" fade={true}>
          <Carousel.Item interval={1000}>
            <div
              className="slider-item"
              style={{
                backgroundImage: `url(${hero1})`,
              }}
            ></div>
          </Carousel.Item>
          <Carousel.Item interval={1000} fade={true}>
            <div
              className="slider-item"
              style={{
                backgroundImage: `url(${hero2})`,
              }}
            ></div>
          </Carousel.Item>
          <Carousel.Item interval={1000} fade={true}>
            <div
              className="slider-item"
              style={{
                backgroundImage: `url(${hero3})`,
              }}
            ></div>
          </Carousel.Item>
        </Carousel>
      </section>
    </div>
  );
};

export default SliderCarousel;
