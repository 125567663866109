import eggbriyani from "../assets/menuimages/biriyani/eggbriyani.jpg";
import eggRice from "../assets/menuimages/fried rice and noodles/eggRice.jpg";
import eggNoodles from "../assets/menuimages/fried rice and noodles/eggNoodles.jpg";
import eggVeechuRoti from "../assets/menuimages/breads/eggVeechuRoti.jpg";
import eggCurry from "../assets/menuimages/curry/eggCurry.jpg";
import eggdosa from "../assets/menuimages/dosa/eggDosa.jpeg";
import eggKothu from "../assets/menuimages/kothu/eggKothu.jpg";
import Omlette from "../assets/menuimages/starters/Omlette.jpg";
import eggpodimass from "../assets/menuimages/starters/eggpodimass.jpg";
import halfBoil from "../assets/menuimages/starters/halfboil.jpg";
import quailEgg from "../assets/menuimages/starters/quailEgg.jpg";
import eggMasalaBonda from "../assets/menuimages/starters/eggMasalaBonda.jpg";




export const eggItems = [
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Egg Half Boil",
    price: 1.90,
    description:
      "Soft-boiled eggs served with a sprinkle of salt and pepper, a classic and comforting breakfast option.",
    image: `url(${halfBoil})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Egg Masala Bonda",
    price: 1.50,
    description:"Similar to masala bonda, but with the addition of boiled eggs along with the potato filling before frying.",
    image: `url(${eggMasalaBonda})`,
  },{
    category: "Starters (Non-Vegetarian)",
    foodName: "Quail eggs (6)",
    price: 6.25,
    description:"Quail eggs are small eggs laid by quail birds, known for their delicate flavor and small size, often used in various cuisines for their versatility in dishes like salads, appetizers, and as a garnish.",
    image: `url(${quailEgg})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Omlette",
    price: 3.75,
    description:
      "Fluffy and flavorful omelette made with eggs and assorted fillings such as onions, tomatoes, and bell peppers, a classic breakfast option.",
    image: `url(${Omlette})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Egg Podimass",
    price: 5.00,
    description:
      "Scrambled eggs cooked with onions, tomatoes, and spices, a simple yet delicious South Indian egg dish..",
    image: `url(${eggpodimass})`,
  },
  {
    category: "Breads",
    foodName: "Egg Veechu Roti",
    price: 6.25,
    description:
      "Flavorful and protein-rich Indian bread made with whole wheat flour and eggs, a hearty and satisfying meal option.",
    image: `url(${eggVeechuRoti})`,
  },
  {
    category: "Veg Noodles",
    foodName: "Egg Noodles",
    price: 7.50,
    description:
      "Fragrant and flavorful rice noodles stir-fried with eggs and assorted vegetables.",
      image: `url(${eggNoodles})`,
  },
  {
    category: "Biryani Veg",
    foodName: "Egg Briyani",
    price: 6.25,
    description:
      "A flavorful rice dish cooked with fragrant basmati rice, eggs, and a blend of spices, a satisfying and wholesome meal option.",
    image: `url(${eggbriyani})`,
  },
  {
        category: "Veg Fried Rice",
        foodName: "Egg Rice",
        price: 7.50,
        description:
          "A classic and satisfying meal option.",
          image: `url(${eggRice})`,
      },
      {
        category: "Veg Curry",
        foodName: "Egg Curry",
        price: 6.25,
        description:
          "Hard-boiled eggs cooked in a flavorful and aromatic gravy made with onions, tomatoes, and a blend of spices, a comforting and hearty dish.",
        image: `url(${eggCurry})`,
    },
    {
    category: "Veg - Dosa",
    foodName: "Egg Dosa",
    price: 7.50,
    description:
      "Flavorful and protein-rich dosa filled with a delicious egg mixture, a hearty and satisfying breakfast option.",
    image: `url(${eggdosa})`,
  },
  {
    category: "Kothu",
    foodName: "Egg Kothu",
    price: 6.90,
    description:
      "Egg kothu is a flavorful South Indian street food dish made with chopped paratha, scrambled eggs, and a medley of spices, offering a satisfying blend of textures and flavors.",
    image: `url(${eggKothu})`,
},
];