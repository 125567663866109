import chickenKothu from "../assets/menuimages/kothu/chickenKothu.jpg";
import vegKothu from "../assets/menuimages/kothu/vegKothu.jpg";
import muttonKothu from "../assets/menuimages/kothu/muttonKothu.jpg";
import prawnKothu from "../assets/menuimages/kothu/prawnKothu.jpg";
import splKothu from "../assets/menuimages/kothu/splKothu.jpg";
import mixKothu from "../assets/menuimages/kothu/mixKothu.jpg";



export const kothu=[
    {
        category: "Kothu",
        foodName: "Veg Kothu",
        price: 6.25,
        description:
          "A popular South Indian street food made with shredded vegetables, spices, and chopped roti.",
        image: `url(${vegKothu})`,
    },
    {
        category: "Kothu",
        foodName: "Chicken Kothu",
        price: 7.50,
        description:
          "Spicy and savory shredded chicken cooked with spices and chopped roti.",
        image: `url(${chickenKothu})`,
    },
    {
        category: "Kothu",
        foodName: "Mutton Kothu",
        price: 9.40,
        description:
          "Tender mutton cooked with aromatic spices and shredded roti, a flavorful delicacy.",
        image: `url(${muttonKothu})`,
    },
    {
        category: "Kothu",
        foodName: "Prawn Kothu",
        price: 8.70,
        description:
          "Succulent prawns sautéed with spices and mixed with shredded roti, a seafood lover's delight.",
        image: `url(${prawnKothu})`,
    },
    {
      category: "Kothu",
      foodName: " Mix Kothu",
      price: 8.75,
      description:
        "A delightful combination of mixed vegetables, meat, or seafood cooked with spices and shredded roti.",
      image: `url(${mixKothu})`,
  },
  {
    category: "Kothu",
    foodName: " Food Station Spl Kothu",
    price: 9.40,
    description:
      "Chef's special kothu dish with a unique twist and flavorful ingredients.",
    image: `url(${splKothu})`,
},
  
]