import vegSamosa from "../assets/menuimages/starters/vegSamosa.jpg";
import vegroll from "../assets/menuimages/starters/vegRoll.jpg"
import vadai from "../assets/menuimages/starters/vadai.jpg";
import masalaVadai from "../assets/menuimages/starters/masalaVadai.jpg"
import masalaBonda from "../assets/menuimages/starters/masalaBonda.jpg"
import bananaSweetBonda from "../assets/menuimages/starters/bananaSweetBonda.jpg"
import onionBajji from "../assets/menuimages/starters/onionBajji.jpg"
import chilliBajji from "../assets/menuimages/starters/chilliBajji.jpg"
import bananaBajji from "../assets/menuimages/starters/bananaBajji.jpg"
import paneer65 from "../assets/menuimages/starters/paneer65.jpg"
import mushroom65 from "../assets/menuimages/starters/mushroom65.jpg"
import cauliflower65 from "../assets/menuimages/starters/cauliflower65.jpg";
import chilliGobi from "../assets/menuimages/starters/chilliGobi.jpg";
import chillipaneer from "../assets/menuimages/starters/chilliPaneer.jpg";
import sambarVadai from "../assets/menuimages/starters/sambarVadai.jpg";
import thayirVadai from "../assets/menuimages/starters/thayirVadai.jpg";
import paneerManchurian from "../assets/menuimages/starters/paneermanchurian.jpg";
import gobiManchurian from "../assets/menuimages/starters/gobimanchurian.jpg";
import chilliIdly from "../assets/menuimages/starters/chilliIdly.jpg";
import chilliParota from "../assets/menuimages/starters/chilliParota.jpg";

//Non - veg starters
import prawnPepperFry from "../assets/menuimages/starters/prawnPepperFry.jpg";
import prawnThokku from "../assets/menuimages/starters/prawnThokku.jpg";
import nethiliFry from "../assets/menuimages/starters/nethiliFry.jpg";
import chicken65 from "../assets/menuimages/starters/chicken65.jpg";
import chickenSamosa from "../assets/menuimages/starters/chickensamosa.jpg";
import kaadaiPepperFry from "../assets/menuimages/starters/kaadaiPepperFry.jpg";
import chickenManchurian from "../assets/menuimages/starters/chickenManchurian.jpg";
import chilliChicken from "../assets/menuimages/starters/chillichicken.jpg";
import pepperChicken from "../assets/menuimages/starters/pepperchicken.jpg";
import garlicChicken from "../assets/menuimages/starters/garlicchicken.jpg";
import gingerChicken from "../assets/menuimages/starters/gingerchicken.jpg";
import lambSamosa from "../assets/menuimages/starters/lambsamosa.jpg";
import lambRoll from "../assets/menuimages/starters/lambRoll.jpg";
import chickenLiver from "../assets/menuimages/starters/chickenLiver.jpg";
import lambChukka from "../assets/menuimages/starters/lambchukka.jpg";
import fishFry from "../assets/menuimages/starters/fishFry.jpg";
import prawn65 from "../assets/menuimages/starters/prawn65.jpg";
import chickenChukka from "../assets/menuimages/starters/chickenChukka.jpg";


export const starterVeg = [
  {
    category: "Starters (Vegetarian)",
    foodName: "Veg Samosa(4PCS)",
    price: 3.15,
    description:
      "Crispy and flaky pastry filled with a savory mixture of spiced vegetables, a popular snack enjoyed across India.",
    image: `url(${vegSamosa})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Veg Roll(4PCS)",
    price: 3.15,
    description:
      "Delicious and filling rolls filled with a flavorful mixture of vegetables, wrapped in a crispy outer layer, a perfect snack or appetize.",
    // image: `url(${vegroll})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Vadai(4PCS)",
    price: 3.15,
    description:
      "Crispy and savory lentil fritters, spiced with herbs and spices, a popular South Indian snack or accompaniment.",
    image: `url(${vadai})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Masala / Dal vadai (4)",
    price: 3.15,
    description:"Masala vadai is a popular South Indian snack made from coarsely ground lentils, primarily chana dal, mixed with onions, spices, and herbs, then deep-fried until crispy.",
    image: `url(${masalaVadai})`,
  },{
    category: "Starters (Vegetarian)",
    foodName: "Masala Bonda",
    price: 1.15,
    description:"A popular South Indian snack where a spicy potato filling is coated with a gram flour batter and deep-fried until golden brown.",
    image: `url(${masalaBonda})`,
  },{
    category: "Starters (Vegetarian)",
    foodName: "Onion Bajji (2)",
    price: 1.00,
    description:"Sliced onions dipped in a spiced gram flour batter and deep-fried until crispy, served as a tasty snack or appetizer.",
    image: `url(${onionBajji})`,
  },{
    category: "Starters (Vegetarian)",
    foodName: "Chilli Bajji (2)",
    price: 1.90,
    description:"Whole green chillies coated in a seasoned gram flour batter and deep-fried until crisp, offering a spicy and flavorful snack option.",
    image: `url(${chilliBajji})`,
  },{
    category: "Starters (Vegetarian)",
    foodName: "Banana Bajji (2)",
    price: 3.15,
    description:"Ripe banana slices dipped in a spiced gram flour batter and deep-fried, creating a sweet and savory snack with a crispy exterior.",
    image: `url(${bananaBajji})`,
  },{
    category: "Starters (Vegetarian)",
    foodName: "Banana Sweet Bonda",
    price: 1.00,
    description:"A sweet variant of bonda, where ripe banana slices are coated in a sweetened batter and deep-fried until golden brown.",
    image: `url(${bananaSweetBonda})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Chilli Gobi",
    price: 7.50,
    description:
      "Stir-fried cauliflower florets tossed in a spicy and tangy sauce, a popular Indo-Chinese dish bursting with flavor.",
    image: `url(${chilliGobi})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Chilli Paneer",
    price: 7.50,
    description:
      "Soft and succulent paneer cubes stir-fried with bell peppers, onions, and a spicy sauce, a delightful fusion dish loved by many.",
    image: `url(${chillipaneer})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Sambar Vadai",
    price: 4.40,
    description:
      "Crispy and savory lentil fritters soaked in tangy and flavorful sambar, a traditional South Indian delicacy bursting with flavors.",
    image: `url(${sambarVadai})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Thayir Vadai",
    price: 4.40,
    description:
      " Soft and spongy lentil fritters soaked in creamy and tangy yogurt, garnished with spices and herbs, a refreshing and delicious snack.",
    image: `url(${thayirVadai})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Cauliflower 65",
    price: 6.90,
    description:
    "Crispy and spicy cauliflower florets, deep-fried to perfection and seasoned with aromatic spices, a flavorful appetizer or snack.",
    image: `url(${cauliflower65})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Mushroom 65",
    price: 6.90,
    description:"Succulent mushroom pieces marinated in a spicy blend of Indian spices, coated with a crispy outer layer, and tossed in a tempering of curry leaves, onions, and capsicum for a delicious Indo-Chinese appetizer.",
    image: `url(${mushroom65})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Paneer 65",
    price: 6.90,
    description:"Spicy marinated paneer cubes, deep-fried to crispy perfection and tossed in a flavorful tempering of curry leaves, onions, and capsicum.",
    image: `url(${paneer65})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Gobi Manchurian",
    price: 7.50,
    description:
      " Stir-fried cauliflower florets coated in a tangy and flavorful sauce, a popular Indo-Chinese appetizer loved for its crunchy texture and bold flavors.",
    image: `url(${gobiManchurian})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Panner Manchurian",
    price: 7.50,
    description:
      "Tender paneer cubes coated in a spicy and tangy sauce, a mouthwatering Indo-Chinese dish perfect for vegetarians.",
    image: `url(${paneerManchurian})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Chilli Idli",
    price: 8.75,
    description:
      "Soft and fluffy idlis stir-fried with bell peppers, onions, and a spicy sauce, a delicious twist on the traditional South Indian breakfast.",
    image: `url(${chilliIdly})`,
  },
  {
    category: "Starters (Vegetarian)",
    foodName: "Chilli Parota",
    price: 8.75,
    description:
      "Flaky and layered parotas stir-fried with bell peppers, onions, and a spicy sauce, a flavorful and indulgent dish loved by many.",
    image: `url(${chilliParota})`,
  },
];
export const starterNonVeg = [
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Chicken 65",
    price: 8.15,
    description:
      "Crispy and spicy chicken bites seasoned with aromatic spices, a popular Indian appetizer or snack.",
    image: `url(${chicken65})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Chicken Samosa(4PCS)",
    price: 3.15,
    description:
      "Crispy and flaky pastry filled with a spicy and flavorful chicken filling, a classic Indian snack enjoyed by many.",
    image: `url(${chickenSamosa})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Chicken Roll(3PCS)",
    price: 3.15,
    description:
      "Flavorful and filling rolls filled with spicy chicken filling and wrapped in a crispy outer layer, a delicious snack or appetizer.",
    // image: `url(${chickenRoll})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Chicken Manchurian",
    price: 8.75,
    description:
      "Tender chicken pieces coated in a spicy and tangy sauce, a popular Indo-Chinese dish loved for its bold flavors and crunchy texture.",
    image: `url(${chickenManchurian})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Chilli Chicken",
    price: 8.75,
    description:
      "Succulent chicken pieces stir-fried with bell peppers, onions, and a spicy sauce, a mouthwatering Indo-Chinese delicacy.",
    image: `url(${chilliChicken})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Pepper Chicken",
    price: 8.15,
    description:
      "Tender chicken pieces cooked with black pepper, onions, and spices, a flavorful and aromatic dish perfect for pepper lovers.",
    image: `url(${pepperChicken})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Garlic Chicken",
    price: 8.15,
    description:
      "Juicy chicken pieces sautéed with garlic, ginger, and aromatic spices, a delicious and comforting dish loved by many.",
    image: `url(${garlicChicken})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Ginger Chicken",
    price: 8.15,
    description:
      "Flavorful chicken curry cooked with ginger, garlic, and aromatic spices, a comforting and aromatic dish perfect for any occasion..",
    image: `url(${gingerChicken})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Chicken Chukka",
    price: 8.15,
    description:
      "Succulent chicken pieces cooked with onions, tomatoes, and aromatic spices, a spicy and flavorful South Indian delicacy.",
    image: `url(${chickenChukka})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Chicken Liver",
    price: 8.15,
    description:"A nutrient-rich organ meat, often sautéed or grilled, prized for its robust flavor and tender texture, commonly enjoyed in various culinary traditions worldwide.",
    image: `url(${chickenLiver})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Lamb Samosa(4PCS)",
    price: 3.15,
    description:
      "Crispy and flaky pastry filled with a spicy and aromatic lamb filling, a delightful twist on the classic Indian snack.",
    image: `url(${lambSamosa})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Lamb Roll(3PCS)",
    price: 3.75,
    description:
      "Flavorful rolls filled with spicy and succulent lamb filling, wrapped in a crispy outer layer, a perfect snack or appetizer.",
    image: `url(${lambRoll})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Lamb Chukka",
    price: 9.40,
    description:
      "Tender lamb pieces cooked with onions, tomatoes, and aromatic spices, a spicy and flavorful South Indian delicacy.",
    image: `url(${lambChukka})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Fish Fry",
    price: 8.15,
    description:
      "Crispy and flavorful fish fillets marinated in aromatic spices and deep-fried to perfection, a classic seafood delicacy.",
    image: `url(${fishFry})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Prawn Pepper Fry",
    price: 8.75,
    description:"Succulent prawns marinated in spices and herbs, coated in a crispy batter, and fried until golden brown, a delicious and indulgent seafood dish.",
    image: `url(${prawnPepperFry})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Prawn 65",
    price: 8.75,
    description:
      "Succulent prawns marinated in spices and herbs, coated in a crispy batter, and fried until golden brown, a delicious and indulgent seafood dish.",
    image: `url(${prawn65})`,
  },
  {
    category: "Starters (Non-Vegetarian)",
    foodName: "Nethili Fry",
    price: 6.25,
    description:"Small anchovies marinated in a tangy blend of spices, coated in a crispy batter, and shallow-fried until golden brown, creating a crunchy and flavorful seafood snack or side dish.",
    image: `url(${nethiliFry})`,
  },{
    category: "Starters (Non-Vegetarian)",
    foodName: "Prawn Thokku",
    price: 8.75,
    description:"A spicy and tangy South Indian dish made by sautéing prawns with a blend of onions, tomatoes, ginger, garlic, and a medley of spices, resulting in a rich and flavorful curry often served with rice or bread.",
    image: `url(${prawnThokku})`,
  },{
    category: "Starters (Non-Vegetarian)",
    foodName: "Kaadai Pepper Fry",
    price: 8.15,
    description:"Kaadai pepper fry is a delectable South Indian dish where quail pieces are marinated in a blend of spices and then stir-fried with plenty of black pepper, curry leaves, and other aromatics, resulting in a deliciously spicy and flavorful dish with a crispy exterior.",
    image: `url(${kaadaiPepperFry})`,
  },
];
