import roseMilk from "../assets/menuimages/drinksndeserts/roseMilk.jpg";
import appleJuice from "../assets/menuimages/drinksndeserts/appleJuice.jpg";
import orangeJuice from "../assets/menuimages/drinksndeserts/orangeJuice.jpg";
import lemonJuice from "../assets/menuimages/drinksndeserts/lemonJuice.jpg";
import lemonMint from "../assets/menuimages/drinksndeserts/lemonMint.jpg";
import lemonSoda from "../assets/menuimages/drinksndeserts/lemonSoda.jpg";
import pineapple from "../assets/menuimages/drinksndeserts/pineapple.jpg";
import carrot from "../assets/menuimages/drinksndeserts/carrot.jpg";
import appleGinger from "../assets/menuimages/drinksndeserts/appleGinger.jpg";
import carrotOrange from "../assets/menuimages/drinksndeserts/carrotOrange.jpg";
import pineappleCarrot from "../assets/menuimages/drinksndeserts/pineappleCarrot.jpg";

import mangolassi from "../assets/menuimages/drinksndeserts/mangolassi.jpg";
import lassi from "../assets/menuimages/drinksndeserts/lassi.jpeg";
import ravaKasari from "../assets/menuimages/drinksndeserts/ravaKasari.jpg";
import gulabjamun from "../assets/menuimages/drinksndeserts/gulabjamun.jpg";
import kulfiStick from "../assets/menuimages/drinksndeserts/kulfiStick.jpg";
import gulabjamunWithIcecream from "../assets/menuimages/drinksndeserts/gulabjamunWithIcecream.jpg";

export const drinks = [
  {
    category: "Drinks",
    foodName: "Rose Milk",
    price: 3.15,
    description:
      "A fragrant and refreshing beverage made with milk and rose syrup, a popular choice for cooling down on hot days.",
    image: `url(${roseMilk})`,
  },
  {
    category: "Drinks",
    foodName: "apple Juice",
    price: 5.00,
    description:"Refreshing and nutritious juices made from a variety of fruits or vegetables, perfect for quenching thirst and boosting health.",
    image: `url(${appleJuice})`,
  },
  {
    category: "Drinks",
    foodName: "Orange Juice",
    price: 5.00,
    description:"Orange juice is a refreshing beverage made from freshly squeezed oranges, packed with vitamin C and a zesty citrus flavor that brightens any day.",
    image: `url(${orangeJuice})`,
  },
  {
    category: "Drinks",
    foodName: "Lemon Juice",
    price: 3.15,
    description:"Lemon juice is a tart and refreshing citrus liquid commonly used in cooking, baking, and beverages, prized for its tangy flavor and versatility in enhancing dishes or drinks with a zesty kick.",
    image: `url(${lemonJuice})`,
  },
  {
    category: "Drinks",
    foodName: "Lemon & mint",
    price: 3.75,
    description:"Lemon and mint combine to create a refreshing and invigorating flavor profile, perfect for enhancing beverages, salads, desserts, and savory dishes with a zesty citrus twist and a cool, herbaceous kick.",
    image: `url(${lemonMint})`,
  },
  {
    category: "Drinks",
    foodName: "Lemon soda ",
    price: 4.10,
    description:"Lemon soda is a refreshing beverage made by combining carbonated water with freshly squeezed lemon juice and sweetener, offering a zesty and bubbly citrus flavor perfect for quenching thirst on hot days.",
    image: `url(${lemonSoda})`,
  },
  {
    category: "Drinks",
    foodName: "Pineapple Juice",
    price: 5.00,
    description:"Pineapple, a tropical fruit known for its sweet and tangy flavor, is rich in vitamin C and adds a refreshing twist to both sweet and savory dishes.",
    image: `url(${pineapple})`,
  },
  {
    category: "Drinks",
    foodName: "Carrot Juice",
    price: 5.00,
    description:"Carrots are crunchy, vibrant root vegetables packed with beta-carotene, essential for eye health, and versatile in culinary applications, from salads to soups and desserts.",
    image: `url(${carrot})`,
  },
  {
    category: "Drinks",
    foodName: "Apple and ginger Juice",
    price: 5.35,
    description:"Apple and ginger combine to create a refreshing and zesty flavor profile, offering a harmonious balance between the sweetness of the apple and the spiciness of the ginger, perfect for juices, sauces, or baked goods.",
    image: `url(${appleGinger})`,
  },
  {
    category: "Drinks",
    foodName: "Carrot and orange Juice",
    price: 5.35,
    description:"Carrot and orange is a vibrant and refreshing combination that offers a harmonious blend of sweet and tangy flavors, packed with vitamins and antioxidants, perfect for a nutritious and invigorating drink or salad.",
    image: `url(${carrotOrange})`,
  },
  {
    category: "Drinks",
    foodName: "Pineapple and carrot Juice",
    price: 5.35,
    description:"Pineapple and carrot combine to create a refreshing and tropical twist, balancing sweet and earthy flavors in a vibrant and nutritious pairing.",
    image: `url(${pineappleCarrot})`,
  },
 
  
];

export const desserts = [
  {
    category: "Desserts",
    foodName: "Lassi Mango",
    price: 3.75,
    description:
      "A refreshing and creamy yogurt-based drink flavored with sweet mango pulp.",
    image: `url(${mangolassi})`,
  },
  {
    category: "Desserts",
    foodName: "Lassi Salt",
    price: 3.75,
    description:
      "A savory version of lassi, a yogurt-based drink, flavored with salt and spices.",
    image: `url(${lassi})`,
  },
  {
    category: "Desserts",
    foodName: "Lassi Sugar",
    price: 3.75,
    description:
      "A sweet and creamy yogurt-based drink flavored with sugar and optionally garnished with cardamom or saffron.",
    image: `url(${lassi})`,
  },
  {
    category: "Desserts",
    foodName: "Rava Kasari",
    price: 1.25,
    description:
      "A delectable Indian dessert made with semolina (rava), sugar, ghee, and flavored with saffron and cardamom.",
    image: `url(${ravaKasari})`,
  },
  // {
  //   category: "Desserts",
  //   foodName: "Kulfi Pots",
  //   price: 5.00,
  //   description:
  //     "Creamy and rich Indian ice cream served in small earthenware pots, available in various flavors.",
  //   image: `url(${kulfiPots})`,
  // },
  {
    category: "Desserts",
    foodName: "Kulfi Stick",
    price: 2.50,
    description:
      "A classic Indian frozen dessert made with thickened milk, nuts, and flavored with saffron, served on a stick.",
    image: `url(${kulfiStick})`,
  },
  {
    category: "Desserts",
    foodName: "Gulab Jamun with Ice cream(2 Nos)",
    price: 5.65,
    description:
      "Warm and syrupy gulab jamun served with a scoop of cold and creamy ice cream, a delightful dessert combination.",
    image: `url(${gulabjamunWithIcecream})`,
  },
  {
    category: "Desserts",
    foodName: "Gulab Jamun (4 Nos)",
    price: 3.15,
    description:"Gulab Jamun, a classic Indian dessert, consists of four delicately sweet and soft milk-solid dumplings soaked in a fragrant sugar syrup, offering indulgent satisfaction in every bite.",
    image: `url(${gulabjamun})`,
  },
  
];
