import React from 'react';
import googleplaystore from "../assets/images/GooglePlayStore.png";
import appleplaystore from "../assets/images/ApplePlayStore.png";
import logo from "../assets/images/croydon.jpg";
import { Image } from "react-bootstrap";

const GoToAppStore = () => {
  return (
    <div align = "center">
      <h2>CROYDON FOOD STATION</h2>

       <Image src={logo} className="d-inline-block align-top" />
      <div className="col-md-5 mb-5">
                  <h3>Download Croydon Food Ordering App to Order now </h3>
                  <ul className="list-unstyled footer-link">
                    <li>
                    <a href="https://play.google.com/store/apps/details?id=com.croydonfoodstation"><img src={googleplaystore} alt="React Image" className="img-fluid about_img_1"/></a>
                    </li>
                    <li>
                     <a href="https://apps.apple.com/in/app/croydon-food-station/id6477545869"><img src={appleplaystore} alt="React Image" className="img-fluid about_img_1" /></a>
                    </li>
                  </ul>
                </div> 
    </div>
  );
}

export default GoToAppStore;
