import parottaChickenCurry from "../assets/menuimages/meal/parottaChickenCurry.jpg";
import parottaLambCurry from "../assets/menuimages/meal/parottaLambCurry.jpeg";
import chickencurryRicePapadam from "../assets/menuimages/meal/chickencurryRicePapadam.jpg";
import chapathiWithVegetableKurma from "../assets/menuimages/meal/chapathiWithVegetableKurma.jpeg";
import muttoncurryRice from "../assets/menuimages/meal/muttoncurryRice.jpg";
import fishcurryRice from "../assets/menuimages/meal/whiteRiceFishCurry.jpg";
import prawncurryRice from "../assets/menuimages/meal/prawncurryRice.jpg";
import kaldosaWithChickencurry from "../assets/menuimages/meal/kaldosaWithChickencurry.jpg";
import whiteRiceFishCurry from "../assets/menuimages/meal/whiteRiceFishCurry.jpg";
import dosaWithChickenCurry from "../assets/menuimages/meal/dosaWithChickenCurry.jpg";



export const VegMeal=[
    {
        category: "Veg Meal",
        foodName: "Rice / 4 Vegi& Papad",
        price: 6.90,
        description:
          "A wholesome meal featuring rice served with four varieties of vegetable curries and crispy papadums.",
        // image: `url(${riceVegi})`,
    },
]

export const NonVegMeal=[
    {
        category: "Non - Veg Meal",
        foodName: "Chicken Curry / Rice & 3 Veg Curry + Papad",
        price: 7.20,
        description:
          "A hearty meal consisting of chicken curry, rice, three vegetable curries, and crispy papadums.",
        image: `url(${chickencurryRicePapadam})`,
    },
    {
        category: "Non - Veg Meal",
        foodName: "Mutton Curry / Rice & 3 Veg Curry + Papad",
        price: 7.85,
        description:
          "A flavorful combination of mutton curry, rice, three vegetable curries, and crispy papadums, perfect for meat lovers.",
        image: `url(${muttoncurryRice})`,
    },
    {
        category: "Non - Veg Meal",
        foodName: "Prawn Curry / Rice & 3 Veg Curry + Papad",
        price: 9.90,
        description:
          "Succulent prawn curry served with rice, three vegetable curries, and crispy papadums, a seafood lover's delight.",
        image: `url(${prawncurryRice})`,
    },
    {
        category: "Non - Veg Meal",
        foodName: "Fish Curry / Rice & 3 Veg Curry + Papad",
        price: 8.15,
        description:
          "Delicious fish curry served with rice, three vegetable curries, and crispy papadums, a satisfying meal option.",
        image: `url(${fishcurryRice})`,
    },
]

export const ComboVegMeal=[
    {
        category: "Combo Veg Meal",
        foodName: "Chappathi with Vegetable Kurma",
        price: 6.25,
        description:
          "Soft and fluffy chappathis served with a flavorful and aromatic vegetable kurma, a wholesome and nutritious meal.",
        image: `url(${chapathiWithVegetableKurma})`,
    }, 
]

export const ComboNonVegMeal=[
    {
        category: "Combo Non - Veg Meal",
        foodName: "Parota (2 No) with Chicken Curry",
        price: 7.50,
        description:
          "Flaky and layered parotas served with spicy and tangy chicken curry, a classic combination enjoyed across India.",
        image: `url(${parottaChickenCurry})`,
    },
    {
        category: "Combo Non - Veg Meal",
        foodName: "Parota (2 No) with Lamb Curry",
        price: 9.40,
        description:
          "Buttery and soft parotas paired with flavorful and tender lamb curry, a hearty and satisfying meal option.",
        image: `url(${parottaLambCurry})`,
    },
    {
        category: "Combo Non - Veg Meal",
        foodName: "Kal Dosa (2 No) with Chicken Curry",
        price: 7.50,
        description:
          "Crispy and thin kal dosas served with spicy and succulent chicken curry, a popular South Indian dish.",
        image: `url(${kaldosaWithChickencurry})`,
    },
    {
        category: "Combo Non - Veg Meal",
        foodName: "Kal Dosa (2 No) with Lamb Curry",
        price: 9.40,
        description:
          "Mouthwatering kal dosas accompanied by flavorful and aromatic lamb curry, a delicious meal choice.",
        image: `url(${kaldosaWithChickencurry})`,
    },
    {
        category: "Combo Non - Veg Meal",
        foodName: "Dosa with Chicken Curry",
        price: 7.50,
        description:
          "Traditional South Indian dosas served with spicy and delicious chicken curry, a perfect combination of flavors and textures.",
        image: `url(${dosaWithChickenCurry})`,
    },
    {
        category: "Combo Non - Veg Meal",
        foodName: "Dosa with Lamb Curry",
        price: 9.40,
        description:
          "Savory and crispy dosas served with tender and flavorful lamb curry, a delightful meal option for meat lovers",
        image: `url(${dosaWithChickenCurry})`,
    },
    {
      category: "Combo Non - Veg Meal",
      foodName: "Plain rice and fish curry",
      price: 10.00,
      description:"Plain rice and fish curry is a classic dish enjoyed in many coastal regions, featuring perfectly cooked rice served alongside a flavorful fish curry made with spices, coconut milk or tamarind for tanginess, and sometimes vegetables like tomatoes or potatoes, creating a balanced and satisfying meal.",
      image: `url(${whiteRiceFishCurry})`,
  },
]