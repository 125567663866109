import tomatosoup from "../assets/menuimages/soups/tomatosoup.jpg";
import lambSoup from "../assets/menuimages/soups/lambSoup.jpeg";

export const soup = [
  {
    category: "Soups",
    foodName: "Veg Soup",
    price: 2.50,
    description:
      "A comforting and nutritious soup made with assorted vegetables, perfect for warming up on chilly days.",
    image: `url(${tomatosoup})`,
  },
  {
    category: "Soups",
    foodName: "Lamb Soup",
    price: 3.75,
    description:
      "A hearty and flavorful soup made with tender pieces of lamb and aromatic spices, a comforting dish for any occasion.",
    image: `url(${lambSoup})`,
  },
  
];
